<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="content-icon reset">
                        <img src="/build/flappy/bird_key.png" class="reset"/>
                    </div>
                    <div class="title">{{ $t('reset_password_success.title') }}</div>
                    <div class="container-content-modal">
                        <div class="container-message">
                            {{ $t('reset_password_success.description') }}
                        </div>
                    </div>
                    <div class="action-form">
                        <button class="btn-tbf blue center" @click="redirect()">
                            <div class="text">{{ $t('reset_password_success.button-action') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
	data(){
		return {
		}
	},
	methods: {
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>